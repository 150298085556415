import { BusinessProfile, BusinessProfileChangeLog } from "../models/sellerAccount.model";
import { TimeFormatService } from "../services";
import { APPCONSTANT } from "../constants";
import { SELLER_ACCOUNT_STATUS, SELLER_ACCOUNT_STATUS_LABEL } from "../../amm-pages/seller-accounts-page/seller-accounts-page.component";
import { BADGE_COLOR } from "../components/amm-badge/amm-badge.component";

export function getAccountCreationTime(sellerAccount: BusinessProfile): string {
    if (sellerAccount.changeLogs) {
        const creationChangeLog = sellerAccount.changeLogs.filter(x => x.status === 'CREATED')[0];
        return TimeFormatService.formatDateTime(creationChangeLog?.createdDate, APPCONSTANT.HOUR_DATE_FORMAT);
    }

    return '';
}

export function sortAccountChangeLogs(logs: BusinessProfileChangeLog[]): BusinessProfileChangeLog[] {
    const res: BusinessProfileChangeLog[] = logs.sort(
        (a, b) =>
            new Date(a.createdDate!).getTime() -
            new Date(b.createdDate!).getTime()
    );

    return res;
}

export function getAccountStatusLabel(sellerAccount: BusinessProfile): string {
    switch (sellerAccount.status) {
        case SELLER_ACCOUNT_STATUS.INACTIVE:
            return SELLER_ACCOUNT_STATUS_LABEL.SUSPENDED;
        case SELLER_ACCOUNT_STATUS.DRAFT:
            return SELLER_ACCOUNT_STATUS_LABEL.PENDING;
        case SELLER_ACCOUNT_STATUS.ACTIVE:
            return sellerAccount.isProfileComplete ? SELLER_ACCOUNT_STATUS_LABEL.ACTIVE : SELLER_ACCOUNT_STATUS_LABEL.INCOMPLETE;
        default:
            return '';
    }
}

export function getAccountStatusColor(sellerAccount: BusinessProfile): string {
    switch (getAccountStatusLabel(sellerAccount)) {
        case SELLER_ACCOUNT_STATUS_LABEL.ACTIVE:
            return BADGE_COLOR.SUCCESS;
        case SELLER_ACCOUNT_STATUS_LABEL.PENDING:
            return BADGE_COLOR.WARNING;
        case SELLER_ACCOUNT_STATUS_LABEL.SUSPENDED:
            return BADGE_COLOR.DANGER;
        case SELLER_ACCOUNT_STATUS_LABEL.INCOMPLETE:
            return BADGE_COLOR.GRAY;
        default:
            return '';
    }
}