import { provideHttpClient } from '@angular/common/http';
import { bootstrapApplication, provideProtractorTestingSupport } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppComponent } from './app/app.component';
import routeConfig from './app/app.routes';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}
bootstrapApplication(AppComponent, {
  providers: [
    provideProtractorTestingSupport(),
    provideRouter(routeConfig, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(),
    MessageService,
    DynamicDialogConfig,
    DialogService,
  ],
}).catch((err) => console.error(err));
