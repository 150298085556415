import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BusinessProfile } from '../../models/sellerAccount.model';

@Component({
  selector: 'amm-dialog-confirm',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './amm-dialog-confirm.component.html',
  styleUrl: './amm-dialog-confirm.component.scss'
})
export class AmmDialogConfirmComponent implements OnInit {

  public IMAGE_SIZE: number = 18;
  public MAX_LENGTH: number = 256;
  public inputContent = new UntypedFormControl('');
  public isSuspend: boolean = true;
  public sellerInfo?: BusinessProfile;


  constructor(public ref: DynamicDialogRef<AmmDialogConfirmComponent>) {}
  private dialogConfig = inject(DynamicDialogConfig<AmmDialogConfirmComponent>);

  ngOnInit(): void {
    this.isSuspend = this.dialogConfig?.data?.isSuspend;
    this.sellerInfo = this.dialogConfig?.data?.sellerInfo;
  }

  getTitle(): string {
    return this.isSuspend ? `Suspend ${this.sellerInfo?.store?.storeName} from selling?` :
      `Resume ${this.sellerInfo?.store?.storeName}’s selling access?`;
  }

  getDetails(): string {
    return this.isSuspend ? `Suspending this account will block ${this.sellerInfo?.seller?.nickName} from selling and receiving payouts immediately. ` :
      `Resuming this account will restore ${this.sellerInfo?.seller?.nickName}'s ability to sell and receive payouts. `;
  }

  onCloseBtnClick(): void {
    this.ref.close({btnLabel: 'close'});
  }


  onCancelBtnClick(): void {
    this.ref.close({btnLabel: 'cancel'});
  }

  onConfirmBtnClick(): void {
    this.ref.close({btnLabel: 'confirm', reason: this.inputContent.value.trimStart()});
  }

  hasInput(): boolean {
    return this.inputContent.value.length > 0 && this.inputContent.value.trimStart().length > 0;
  }

  getPlaceholder(): string {
    return this.isSuspend ? 
      "Add notes for suspending the account (for internal use only)" :
      "Add notes for resuming the account (for internal use only)";
  }

  getCharLeft(): string {
    let charsLeft: string = this.MAX_LENGTH - this.inputContent.value.length + '';
    return charsLeft.replace(/\B(?=(\d{3})+(?!\d))/g, ",")  + ' characters remaining';
  }
}
