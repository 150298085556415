
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { CommonModule } from '@angular/common';
import { AmmTextEntryData, NgFormValidator } from '../../constants/global.component';

@Component({
  selector: 'amm-password',
  standalone: true,
  imports: [
    PasswordModule, 
    DividerModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './amm-password.component.html',
  styleUrls: ['./amm-password.component.scss']
})
export class AmmPasswordComponent implements OnInit {

  @Input()
  passwordFrmCnt: FormControl<string | null> = new FormControl('', NgFormValidator.passwordLimitation());;

  @Input()
  entryConfig?: AmmTextEntryData;

  @Output()
  passwordChanged = new EventEmitter<string>();

  component() { }

  ngOnInit(): void {

  }

  noFeedback(): boolean {
    return this.entryConfig?.noFeedback || false;
  }


  getErrorInfo(): string {
    if (this.entryConfig?.error) {
      return this.entryConfig?.errorMsg || '';
    }
    if (this.passwordFrmCnt?.errors) {
      return this.passwordFrmCnt?.errors['errorMessage'];
    }
    return '';
  }

  passwordEmit(): void {
    this.passwordChanged.emit(this.passwordFrmCnt.value || '');
  }

  hasError(): boolean {
    // return (this.passwordFrmCnt.invalid && HasStringValue(this.passwordFrmCnt.value)) || (this.entryConfig?.error || false);

    return (this.entryConfig?.error || false) || (this.passwordFrmCnt.invalid && this.passwordFrmCnt.touched);
  }

  getPasswordClass(): string {
    return this.hasError() ? 'password ng-invalid ng-dirty' : 'password';
  }

  getPlaceHolder(): string {
    return this.entryConfig?.placeholder || '';
  }

}