<div class="sidebar flex flex-col">
    <div class="header-section flex flex-col">
      <div class="header flex items-center justify-center" 
        [class.expand]="onShowHeaderActions"
      >
        <div class="avatar flex flex-col items-center justify-center">{{getAvatar()}}</div>
        <div class="info flex-1">
          <div class="action-label-text">{{getNickName()}}</div>
          <div class="email">{{getLoginName()}}</div>
        </div>
        <span class="material-symbols-outlined cursor-pointer" 
            style="color: #757575;" (click)="onClickHeaderAction()"
            >
          {{onShowHeaderActions ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
          </span>
      </div>

      @if (onShowHeaderActions) {
        <amm-menu-actions class="menu-actions flex" [menuItems]="headerMenuItems"
          (itemEvent)="onHeaderActionsClick($event)">
        </amm-menu-actions>
      }
    </div>

    <div class="actions flex flex-col">
      @for (item of actionList; track $index) {
        <div class="action-item flex items-center" (click)="onActionClick(item)"
          [class.selected]="currentTabName === item.label"
        >
          <div class="action-icon flex items-center">
            <span class="material-symbols-outlined" style="color: #282E38;">
              {{item.iconName}}
              </span>
          </div>
          <div class="action-label action-label-text">{{item.label}}</div>
  
          <!-- Sellers tab -->
          @if (item.label === 'Sellers') {
            @if (!onShowSellerInfo) {
              @if (reviewAccountStore.totalOpenAccountNum() > 0) {
                <p-badge [value]="getReviewAccountNum()"></p-badge>
              } @else {
                <div class="action-icon flex items-center">
                  <span class="material-symbols-outlined" style="color: #282E38;">
                    add
                    </span>
                </div>
              }
            } @else {
              <div class="action-icon flex items-center">
                <span class="material-symbols-outlined" style="color: #282E38;">
                  remove
                  </span>
              </div>
            }
          }
  
          <!-- Messages tab -->
          @if (item.label === 'Messages' && messageStore.hasUnreadMsgs()) {
            <p-badge [value]="messageStore.unreadMsgsCount()"></p-badge>
          }
  
          <!-- Financial tab -->
          @if (item.label === 'Financial') {
            @if (!onShowFinanceInfo) {
              <div class="action-icon flex items-center">
                <span class="material-symbols-outlined" style="color: #282E38;">
                  add
                  </span>
              </div>
            } @else {
              <div class="action-icon flex items-center">
                <span class="material-symbols-outlined" style="color: #282E38;">
                  remove
                  </span>
              </div>
            }
          }
  
        </div>

        <!-- expand sellers tab -->
        @if (onShowSellerInfo && item.label === 'Sellers') {
            @for (child of item.actions; track $index; let childIndex = $index) {
              <div class="action-item flex items-center" (click)="onActionClick(child)"
              [class.selected]="currentTabName === child.label"
              >
                <div class="extend-icon flex items-center">
                  @if (childIndex != ((item.actions?.length || 0) - 1)) {
                    <img src="../../../../assets/images/nav-item-tree-connector.svg" alt="">

                  } @else {
                    <img src="../../../../assets/images/nav-item-tree-connector-end.svg" alt="">

                  }
                </div>
                <div class="action-label action-label-text">{{child.label}}</div>
                @if (child.label === 'Review accounts' && reviewAccountStore.totalOpenAccountNum() > 0 && currentTabName != 'Review accounts') {
                    <p-badge [value]="getReviewAccountNum()"></p-badge>
                }
              </div>
            }
        }

        <!-- expand financial tab -->
        @if (onShowFinanceInfo && item.label === 'Financial') {
            @for (child of item.actions; track $index; let childIndex = $index) {
              <div class="action-item flex items-center" (click)="onActionClick(child)"
              [class.selected]="currentTabName === child.label"
              >
                <div class="extend-icon flex items-center">
                  @if (childIndex != ((item.actions?.length || 0) - 1)) {
                    <img src="../../../../assets/images/nav-item-tree-connector.svg" alt="">

                  } @else {
                    <img src="../../../../assets/images/nav-item-tree-connector-end.svg" alt="">

                  }
                </div>
                <div class="action-label action-label-text">{{child.label}}</div>
              </div>
            }
        }
      }
    </div>
  
  </div>