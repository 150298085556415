<div>
  <router-outlet></router-outlet>
</div>

<p-toast
  key="password-changed-toast"
  [position]="'top-right'"
  [style]="{ 'white-space': 'pre-line' }"
  [life]="4000"
  [preventOpenDuplicates]="true"

/>