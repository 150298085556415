import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AmmNavigationSideBarComponent } from '../../amm-share/components/amm-navigation-side-bar/amm-navigation-side-bar.component';
import { AmmToastWithActionComponent } from '../../amm-share/components/amm-toast-with-action/amm-toast-with-action.component';
import { ToastActionEvent } from '../../amm-share/services/toast-with-action.interface';
import { AmmToastService } from '../../amm-share/services';
import { Message } from 'primeng/api';

@Component({
  selector: 'amm-main-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    AmmNavigationSideBarComponent,
    AmmToastWithActionComponent,
  ],
  templateUrl: './main-page.component.html',
  styleUrl: './main-page.component.scss'
})
export class MainPageComponent {

  private ammToastService = inject(AmmToastService);


  onToastClose(event: Message) {
    this.ammToastService.onToastClose.next(event.data);
  }
  onBtnAction(toastActionEvent: ToastActionEvent) {
    this.ammToastService.onToastActionClicked.next(toastActionEvent);
  }

}
