<div class="modal flex flex-col">
    <div class="flex flex-col gap-6">
        <div class="header-section flex">
            <div class="header">{{onShowAccountInfo ? 'Account information' : 'Change password'}}</div>
            <div class="icon cursor-pointer">
                <span class="material-symbols-outlined close-icon" (click)="onCloseBtnClick()"> clear </span>
            </div>
        </div>

        <div class="info-section flex flex-col gap-4">
            <div class="flex gap-4">
                <div class="flex flex-col gap-1 flex-1">
                    <div class="label">Name</div>
                    <input type="text" [placeholder]="adminInfo?.nickName" disabled>
                </div>
                <div class="flex flex-col gap-1 flex-1">
                    <div class="label">Email</div>
                    <input type="text" [placeholder]="adminInfo?.loginName" disabled>
                </div>
            </div>

            @if (onShowAccountInfo) {
                <div class="flex flex-col gap-1">
                    <div class="label">Password</div>
                    <p-iconField iconPosition="right">
                        <p-inputIcon styleClass="pi pi-eye" />
                        <input class="w-full" type="password" placeholder="********" disabled>
                    </p-iconField>
                </div>
                
            } @else {
                <amm-password [passwordFrmCnt]="oldPasswordFrmCnt" 
                    [entryConfig]="{label:'Old password', noFeedback: true, error: hasOldPwdError, 
                    errorMsg: 'Oops! That\'s not your current password. Try again.'}">
                </amm-password>

                <amm-password [passwordFrmCnt]="newPasswordFrmCnt" 
                    [entryConfig]="{label:'New password', error: hasNewPwdError,
                    errorMsg:'For security reasons, your new password cannot be the same as your current password.'}">
                </amm-password>

                <amm-password [passwordFrmCnt]="confirmPasswordFrmCnt" 
                    [entryConfig]="{label: 'Confirm password', error: getComfirmPasswordError(),
                    errorMsg:'Passwords don\'t match! Make sure the passwords are the same in both fields.'}">
                </amm-password>
                <div class="reminder">You will need to log in again with your new password.</div>
            }

        </div>
    </div>

    @if (onShowAccountInfo) {
    <button (click)="onChangePwdClick()">Change password</button>
    
    } @else {
    <button (click)="onConfirmBtnClick()" 
        [disabled]="hasOldPwdError || hasNewPwdError || !getAllValidInfo()"
    >
        Confirm change password
    </button>
    }
</div>