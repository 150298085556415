import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PJ_ACTION, PjActionEvent } from '../../constants/global.component';

@Component({
  selector: 'amm-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './amm-input.component.html',
  styleUrl: './amm-input.component.scss'
})
export class AmmInputComponent {

  @Input()
  public searchKey: string = '';
  public placeholder = input('');
  public closable = input(true);

  @Output()
  inputEvent = new EventEmitter<PjActionEvent>();

  onSearchKeyChange(): void {
    this.inputEvent.emit({actionString: PJ_ACTION.SEARCH, para: {'searchKey': this.searchKey}});
  }

  onKeydownEnter(): void {
    this.inputEvent.emit({actionString: PJ_ACTION.KEYDOWN, para: {'searchKey': this.searchKey}});

  }

  onCloseBtnClick(): void {
    this.searchKey = '';
    this.inputEvent.emit({actionString: PJ_ACTION.CLOSE, para: {'searchKey': this.searchKey}});

  }

}
