<div class="page-content flex flex-col">
  <div class="title">Seller Accounts</div>
  <div class="search-section flex items-center w-full">
    <amm-input class="flex w-full" 
      [placeholder]="'Seller ID, email, or business name.'"
      (inputEvent)="getSearchEvent($event)">
    </amm-input>

    <div class="filter-section flex items-center cursor-pointer" (click)="onFilterIconClick()" [class.showFilter]="showFilters">
      @if ((selectedAccountStatus.status != ALL_LABEL) || (selectedSellerType.status != ALL_LABEL)) {
        <div class="button-text" style="min-width: 80px;">Clear filters</div>
        <span class="material-symbols-outlined filter-icon">
          clear
        </span>
        
      } @else {
        <span class="material-symbols-outlined filter-icon">
          filter_list
        </span>
        <div class="button-text">Filters</div>
      }
    </div>
  </div>

  <!-- filter actions -->
  @if (showFilters) {
    <div class="flex gap-4 items-center">
      <p-dropdown
        [options]="accountStatusOptions"
        [(ngModel)]="selectedAccountStatus" 
        (onChange)="setAccountStatus($event)"
        optionLabel="status"
        >
        <ng-template pTemplate="selectedItem">
          {{selectedAccountStatus.label}}
        </ng-template>
        <ng-template #status let-item pTemplate="item">
          {{item.status}}
        </ng-template>
      </p-dropdown>

      <p-dropdown
        [options]="sellerTypeOptions"
        [(ngModel)]="selectedSellerType" 
        (onChange)="setSellerType($event)"
        optionLabel="status"
        >
        <ng-template pTemplate="selectedItem">
          {{selectedSellerType.label}}
        </ng-template>
        <ng-template #status let-item pTemplate="item">
          {{item.status}}
        </ng-template>
      </p-dropdown>
    </div>
  }


  @if (!isKeydownPressed && currentInputSearchKey && currentInputSearchKey.length > 0) {
    <div class="flex justify-center text-center no-search-result-text">Press Enter to see search results.</div>
  }

  @if (isKeydownPressed && accountList.length === 0) {
    <div class="flex justify-center text-center no-search-result-text">No result for “{{searchKey}}”</div>
  }

  @if (!isKeydownPressed && accountList.length === 0) {
    <div class="flex justify-center text-center no-search-result-text">No sellers match the selected criteria.</div>
  }


  <!-- seller account table -->
  @if ((isKeydownPressed || (!isKeydownPressed && !currentInputSearchKey)) && accountList.length > 0) {
    <p-table #dt [value]="accountList" [style]="{'width':'100%'}" [scrollable]="true">
      <ng-template pTemplate="header">
        <tr style="height: 45px;">
          <th style="min-width: 100px; max-width: 144px;"><div class="table-header">Seller ID</div></th>
          <th style="min-width: 144px;"><div class="table-header">Account status</div></th>
          <th style="min-width: 160px;"><div class="table-header">Email</div></th>
          <th style="min-width: 96px;"><div class="table-header">Type</div></th>
          <th style="min-width: 132px;"><div class="table-header">Business name</div></th>
          <th style="min-width: 108px;"><div class="table-header">Seller name</div></th>
          <th class="action-btn" alignFrozen="right" pFrozenColumn [frozen]="true"> </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-account>
        <tr>
          <td>
            <div class="table-text">{{account.ownerId}}</div>
          </td>
          <td>
            <amm-badge class="status-badge" [label]="getStatusLabel(account)" [color]="getBadgeColor(account)"></amm-badge>
          </td>
          <td>
            <div class="table-text">{{account.email}}</div>
          </td>
          <td>
            <div class="table-text">{{getAccountType(account)}}</div>
          </td>
          <td>
            <div class="table-text">{{account.name}}</div>
          </td>
          <td>
            <div class="table-text">{{getSellerName(account)}}</div>
          </td>
    
          <td alignFrozen="right" pFrozenColumn [frozen]="true">
              <!-- <div class="action-btn flex flex-col items-center justify-center cursor-pointer" (click)="op.toggle($event)">
                <span class="material-symbols-outlined more-icon">more_vert</span>
                <p-overlayPanel #op class="flex">
                  <amm-menu-actions class="panel-actions" [menuItems]="getTableActions(seller)"
                    (itemEvent)="onMenuActionClick($event, seller)">
                  </amm-menu-actions>
                </p-overlayPanel>
              </div> -->
              
            <p-button 
              class="action-btn" [size]="'small'"
              (onClick)="op.toggle($event)" icon="pi pi-ellipsis-v"
              [text]="true" severity="secondary">
    
              <p-overlayPanel #op>
                <amm-menu-actions [menuItems]="getTableActions(account)"
                  (itemEvent)="onMenuActionClick($event, account)">
                </amm-menu-actions>
              </p-overlayPanel>
            </p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    
    <amm-paginator-table 
      class="w-full"
      [description]="pageDescription"
      (action)="paginatorAction($event)" 
      [isPrevBtnDisable]="isFirstPage()" [isNextBtnDisable]="isLastPage()">
    </amm-paginator-table>

  }

</div>