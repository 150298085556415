import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { AuthService, SseService, StorageService } from './amm-share/services';
import { Subject, timer } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { environment } from '../environments/environment';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ButtonModule, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  private sseService = inject(SseService);
  private storageService = inject(StorageService);
  private authService = inject(AuthService);
  private destory$: Subject<boolean> = new Subject<boolean>();


  ngOnInit(): void {
    const jwt = this.storageService.getJWT();
    if (jwt) {
      this.sseService.connectToSSE(jwt);
    }
    timer(0,environment.jwtExpireCheckInterval).pipe().subscribe(() => {
      const jwt = this.storageService.getJWT();
      if (jwt) {
        const decodedJwt = jwtDecode(jwt);
        const isExpired = new Date().getTime() > (decodedJwt?.exp || 0)*1000;
        if (isExpired) {
          this.authService.logOut();
        }
      }
      
    })
  }

  ngOnDestroy(): void {
    this.sseService.abortSSE();
    this.destory$.next(true);
    this.destory$.complete();
  }
}
