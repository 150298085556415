<div
  class="badge"
  [class.onLabel]="leftConfigType.length === 0 && !hasRightIcon"
  [class.hasBorder]="hasBorder" 
  [ngClass]="getBadgeClass()"
>
  <div *ngIf="leftConfigType === 'dot'" class="dot"></div>
  <span *ngIf="leftConfigType === 'check'" class="material-symbols-rounded check-icon">check</span>
  
  <div class="label">{{ label }}</div>
  
  <span *ngIf="hasRightIcon" class="material-symbols-rounded check-icon">check</span>
</div>
