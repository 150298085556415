<div class="input-section flex flex-col w-full">
    <div class="search-frame flex flex-row flex-grow items-center">
        <span class="material-symbols-outlined search-icon"> search </span>
        <input class="input-box"
            [placeholder]="placeholder()"
            [(ngModel)]="searchKey"
            (ngModelChange)="onSearchKeyChange()"
            (keydown.enter)="onKeydownEnter()"
        />

        @if (closable() && searchKey.length > 0) {
            <span class="material-symbols-outlined search-icon" (click)="onCloseBtnClick()"> clear </span>
        }
    </div>
    <!-- <div class="helper-text pt-1">

    </div> -->
</div>