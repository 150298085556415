import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { CrmFrontMessage, FrontTopMessageSummary, MessageLoadingStatus, MessagesGroupResult, MessagesTopMsgMapping, PagingData, TopMsgMessageGroupMap, TopMsgStoreMap } from '../models';
import { ShopStore } from '../models/sellerAccount.model';
import { AmmMessageService, ProductService } from '../services';
import { generateMsgDayGroupKey, groupMessageByDate } from '../utilities';


export interface InitState {
  conversations: FrontTopMessageSummary[];
  stores: ShopStore[];
  topMsgMessageGroupMap: TopMsgMessageGroupMap[];
  msgTopMsgIdMapping: MessagesTopMsgMapping[];
}

export const initState: InitState = {
  conversations: [],
  stores: [],
  topMsgMessageGroupMap: [],
  msgTopMsgIdMapping: [],
};

export const MessagingStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initState),
  withComputed((store) => ({
    topMsgStoreMaps: computed(() => {
      const conversations = store.conversations();
      const stores = store.stores();
      const result: TopMsgStoreMap[] = [];
      conversations.forEach((con) => {
        const store = stores.find((x) => x.code === con.groupRef);
        if (store && con && con.topMsgId) {
          result.push({
            topMsgId: con.topMsgId,
            store: store,
          });
        }
      });

      return result;
    }),
    hasUnreadMsgs: computed(() => {
      const conversations = store.conversations();
      const unreadCounts = conversations.map((x) => x.unreadCount);
      const sum = unreadCounts
        .filter((x) => x)
        .reduce((acc, curr) => {
          acc = (acc ?? 0) + (curr ? curr : 0);
          return acc;
        }, 0);
      return sum ?? 0 > 0;
    }),
    unreadMsgsCount: computed(() => {
      const conversations = store.conversations();
      const unreadCounts = conversations.map((x) => x.unreadCount);
      const sum = unreadCounts
        .filter((x) => x)
        .reduce((acc, curr) => {
          acc = (acc ?? 0) + (curr ? curr : 0);
          return acc;
        }, 0);
      return sum;
    }),
  })),
  withMethods(
    (
      store,
      ammMsgSrv = inject(AmmMessageService),
      prodSrv = inject(ProductService)
    ) => ({
      loadConversations() {
        ammMsgSrv
          .fetchTopMessage()
          .subscribe((response: PagingData<FrontTopMessageSummary>) => {
            if (response && response.dataList) {
              patchState(store, {
                conversations: response.dataList,
              });
              this.loadStoreInfo();
            }
          });
      },
      loadStoreInfo() {
        const storeCodes =
          store
            .conversations()
            .filter((x) => x.groupRef !== void 0)
            .map((x) => x.groupRef!) || [];
        if (storeCodes && storeCodes.length > 0) {
          prodSrv.getStoreByCodes(storeCodes).subscribe((amStores: ShopStore[]) => {
            patchState(store, {
              stores: amStores ?? [],
            });
          });
        }
      },
      loadConversationById(
        topMsgId: number,
        pageIndex: number = 0,
        pageSize: number = 12
      ) {
        ammMsgSrv
          .fetchConversationByTopMsgId(topMsgId, pageIndex, pageSize)
          .pipe()
          .subscribe((response: PagingData<CrmFrontMessage>) => {
            if (response && response.dataList && response.dataList.length > 0) {
              // group the messages by date
              const messages = response.dataList;

              const index = store
                .msgTopMsgIdMapping()
                .findIndex((x) => x.topMsgId === topMsgId);

              if (index != -1) {
                patchState(store, {
                  msgTopMsgIdMapping: store.msgTopMsgIdMapping().map((x) =>
                    x.topMsgId === topMsgId
                      ? <MessagesTopMsgMapping>{
                          ...x,
                          currentConversationMsgs:
                            x.currentConversationMsgs.concat(messages),
                          totalRecords: response.totalRecords,
                          loadingConversationResult: {
                            ...x.loadingConversationResult,
                            isLoading: false,
                          },
                        }
                      : x
                  ),
                });
              } else {
                patchState(store, {
                  msgTopMsgIdMapping: [
                    ...store.msgTopMsgIdMapping(),
                    <MessagesTopMsgMapping>{
                      topMsgId: topMsgId,
                      currentConversationMsgs: messages,
                      totalRecords: response.totalRecords,
                      loadingConversationResult: <MessageLoadingStatus>{
                        loadingError: false,
                        isLoading: false,
                      },
                    },
                  ],
                });
              }
              const item = store
                .msgTopMsgIdMapping()
                .find((x) => x.topMsgId === topMsgId);

              if (item) {
                const sortedMsgs = item.currentConversationMsgs.sort(
                  (a, b) =>
                    new Date(a.createdDate!).getTime() -
                    new Date(b.createdDate!).getTime()
                );
                const groupByDate = groupMessageByDate(sortedMsgs);
                const messageAlreadyExists = store
                  .topMsgMessageGroupMap()
                  .find((x) => x.topMsgId === topMsgId);
                if (messageAlreadyExists) {
                  TopMsgMessageGroupMap: store
                    .topMsgMessageGroupMap()
                    .map((x) =>
                      x.topMsgId == topMsgId
                        ? (x.groupResults = groupByDate)
                        : x
                    );
                } else {
                  patchState(store, {
                    topMsgMessageGroupMap: [
                      ...store.topMsgMessageGroupMap(),
                      {
                        topMsgId: topMsgId,
                        groupResults: groupByDate,
                      },
                    ],
                  });
                }
              }
            }
          });
      },
      updateLoadingStatus(topMsgId: number, status: boolean) {
        patchState(store, {
          msgTopMsgIdMapping: store.msgTopMsgIdMapping().map((x) =>
            x.topMsgId == topMsgId
              ? {
                  ...x,
                  loadingConversationResult: {
                    ...x.loadingConversationResult,
                    isLoading: status,
                  },
                }
              : x
          ),
        });
      },
      clearCurrentConversationMsgs(topMsgId: number) {
        patchState(store, {
          msgTopMsgIdMapping: store.msgTopMsgIdMapping().map((x) =>
            x.topMsgId == topMsgId
              ? {
                  ...x,
                  currentConversationMsgs: [],
                  totalRecords: 0,
                }
              : x
          ),
        });
      },
      addNewMsgToGroup(topMsgId: number, message: CrmFrontMessage) {
        const key = generateMsgDayGroupKey(message);
        let groupMaps = store.topMsgMessageGroupMap();
        const conversationMap = groupMaps.find((x) => x.topMsgId == topMsgId);
        if (conversationMap) {
          const group = conversationMap.groupResults.find((x) => x.key == key);
          if (group) {
            // if the same day conversation found
            group.messages.push(message);
          } else {
            // if the new day conversation found, we create this key
            groupMaps = groupMaps.map((x) =>
              x.topMsgId == topMsgId
                ? {
                    ...x,
                    groupResults: [
                      ...x.groupResults,
                      <MessagesGroupResult>{
                        key: key,
                        messages: [message],
                      },
                    ],
                  }
                : x
            );
          }
        } else {
          // if the group doesn't exists then we should add to new group
          groupMaps.push(<TopMsgMessageGroupMap>{
            topMsgId: topMsgId,
            groupResults: [
              <MessagesGroupResult>{
                key: key,
                messages: [message],
              },
            ],
          });
        }
        patchState(store, {
          topMsgMessageGroupMap: groupMaps,
        });
      },
      saveNewMsgToDb(topMsgId: number, message: CrmFrontMessage) {
        ammMsgSrv.replyMessageByTopId(message, topMsgId).subscribe();
      },
      handleMsgFromSse(msgId: number, topMsgId: number) {
        ammMsgSrv
          .fetchAllMessageById(msgId)
          .subscribe((response: PagingData<CrmFrontMessage>) => {
            if (response && response.dataList && response.dataList.length > 0) {
              const lastestMsgInConversation = response.dataList[0];
              const topMsgIdFromSse = lastestMsgInConversation.topMsgId ?? 0;

              this.addNewMsgToGroup(topMsgId, lastestMsgInConversation);
              // TODO: here we have to think of a way to identify when the user is on a open conversation
              // if (topMsgId === topMsgIdFromSse) {
              //   this.readMsgs([topMsgId]);
              // }
              this.loadConversations();
            }
          });
      },
      readMsgs(ids: number[]) {
        ammMsgSrv
          .setWholeThreadToReadStatus(ids)
          .subscribe((response: number) => {
            this.loadConversations();
          });
      },
    })
  )
);