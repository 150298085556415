import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'amm-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './amm-badge.component.html',
  styleUrl: './amm-badge.component.scss'
})
export class AmmBadgeComponent {
  @Input() size: string = BADGE_SIZE.SMALL;
  @Input() label: string = 'Label';
  @Input() color: string = BADGE_COLOR.PRIMARY;
  @Input() hasBorder: boolean = false;
  @Input() leftConfigType: string = '';  // 'dot' or 'check'
  @Input() hasRightIcon: boolean = false;

  getBadgeClass(): string {
    return `${this.size} ${this.color}`;
  }
}

export const BADGE_COLOR = {
  PRIMARY: 'primary',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  GRAY: 'gray'
};

export const BADGE_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};
