<div class="dialog flex flex-col">
    <div class="header flex justify-between items-start">
        <div class="symbol" [class.resume]="!isSuspend">
            @if (isSuspend) {
                <img src="../../../../assets/images/warning.svg" alt="" [width]="IMAGE_SIZE" [height]="IMAGE_SIZE">
            
            } @else {
                <img src="../../../../assets/images/replay.svg" alt="" [width]="IMAGE_SIZE" [height]="IMAGE_SIZE">
            
            }
        </div>

        <div class="close-icon" (click)="onCloseBtnClick()">
            <span class="material-symbols-outlined"> clear </span>
        </div>
    </div>

    <div class="content flex flex-col">
        <div class="title">{{getTitle()}}</div>
        <div class="subtitle">{{getDetails()}}</div>
        <div class="flex flex-col gap-1">
            <textarea 
                class="textarea"
                [maxLength]="MAX_LENGTH"
                [formControl]="inputContent"
                [placeholder]="getPlaceholder()">
            </textarea>
            <div class="left-text">{{getCharLeft()}}</div>
        </div>
    </div>

    <div class="footer flex">
        <button (click)="onCancelBtnClick()">Cancel</button>
        <button class="confirm-btn" (click)="onConfirmBtnClick()"
            [class.resume]="!isSuspend"
            [disabled]="!hasInput()"
        >
            {{isSuspend ? 'Yes, suspend' : 'Yes, resume'}}
        </button>
    </div>
</div>