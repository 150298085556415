import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'amm-menu-actions',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './amm-menu-actions.component.html',
  styleUrl: './amm-menu-actions.component.scss'
})
export class AmmMenuActionsComponent {


  @Input()
  menuItems: Array<MenuItem> | null = null;

  @Output()
  itemEvent = new EventEmitter<MenuItem>();


  onItemClick(item: MenuItem):void {
    this.itemEvent.emit(item);
  }

}


export class MenuItem {
  icon?: string;
  label?: string;
}

