import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PolarJService } from "./polarj.service";
import { PagingData } from "../models";
import { Order } from "../models/order.model";
import { BusinessProfile } from "../models/sellerAccount.model";
import { SELLER_ACCOUNT_STATUS, SELLER_ACCOUNT_STATUS_LABEL } from "../../amm-pages/seller-accounts-page/seller-accounts-page.component";


@Injectable({
  providedIn: 'root'
})

export class MerchantService {
  private _polarjService: PolarJService = inject(PolarJService);

  fetchAdminOrdersBySearch(searchKeyword?: string, pageIndex?: number, pageSize?: number, asc?: boolean): Observable<PagingData<Order>> {
    let _urlPara = 'pageIndex=' + (pageIndex || 0) + '&pageSize=' + (pageSize || 15) + '&sorts=' + 'orderedDateTime' + (asc ? ' asc' : ' desc');
    return this._polarjService.postCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT, modelName: MerchantServiceModelContextPath.ORDERSADMIN,
      requestMappingString: 'quickSearch/', enableErrorMsg: true, singleData: false, urlPara: _urlPara,
      para: searchKeyword,
      pageData: true
    });
  }
  getOrderByPoNumber(poNumber: string): Observable<Order> {
    return this._polarjService.postCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT, modelName: MerchantServiceModelContextPath.ORDERSADMIN,
      requestMappingString: 'quickSearch/', enableErrorMsg: true, singleData: true, urlPara: poNumber,
      para: poNumber,
      pageData: false
    });
  }
  fetchOrderByPoNumber(poNumber: string): Observable<Order> {
    console.log(poNumber);
    return this._polarjService.postCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT, modelName: MerchantServiceModelContextPath.ORDERSADMIN,
      requestMappingString: 'byNumber/' + poNumber, enableErrorMsg: true, singleData: true,
      // urlPara:poNumber,
    });
  }
  fetchSalesOrderById(orderId: string): Observable<Order> {
    return this._polarjService.getCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT, modelName: MerchantServiceModelContextPath.ORDERSADMIN,
      requestMappingString: '' + orderId, enableErrorMsg: true, singleData: true
    });
  }
  fetchSellerById(sellerId: number ,anon?:boolean): Observable<Order> {
    let urlPara='';
    if (anon){
      urlPara='isAnon=true'
    }else{
      urlPara='isAnon=false'
    }
    return this._polarjService.getCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT, 
      modelName: MerchantServiceModelContextPath.BUSINESS_PROFILE_ADMIN,
      urlPara: urlPara,
      requestMappingString: '' + sellerId, enableErrorMsg: true, singleData: true
    });
  }

  fetchBusinessProfileById(id: number): Observable<BusinessProfile> {
    return this._polarjService.postCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT,
      modelName: MerchantServiceModelContextPath.BUSINESS_PROFILE_ADMIN,
      requestMappingString: 'search', enableErrorMsg: true,
      singleData: true,
      para: {id: id}
    });
  }

  fetchBusinessProfileByOwnerId(id: number): Observable<BusinessProfile> {
    return this._polarjService.postCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT,
      modelName: MerchantServiceModelContextPath.BUSINESS_PROFILE_ADMIN,
      requestMappingString: 'search', enableErrorMsg: true,
      singleData: true,
      para: {ownerId: id}
    });
  }

  fetchUserBusinessProfiles(): Observable<PagingData<BusinessProfile>> {
    return this._polarjService.postCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT,
      modelName: MerchantServiceModelContextPath.BUSINESS_PROFILE_ADMIN,
      requestMappingString: 'search', enableErrorMsg: true,
      pageData: true,
      para: {smartSearchText: ''}
    });
  }

  searchSellerAccounts(searchKey?: string, pageIndex: number = 0, pageSize: number = 15, status?: string, businessType?: string): Observable<PagingData<BusinessProfile>> {
    let searchStatus = undefined;
    if (status) {
      searchStatus = status === SELLER_ACCOUNT_STATUS_LABEL.PENDING
        ? SELLER_ACCOUNT_STATUS.DRAFT
        : (status === SELLER_ACCOUNT_STATUS_LABEL.SUSPENDED ? SELLER_ACCOUNT_STATUS.INACTIVE : SELLER_ACCOUNT_STATUS.ACTIVE);

      }
    const isProfileComplete = status === SELLER_ACCOUNT_STATUS_LABEL.ACTIVE ? true : undefined;

    return this._polarjService.postCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT,
      modelName: MerchantServiceModelContextPath.BUSINESS_PROFILE_ADMIN,
      requestMappingString: 'search', enableErrorMsg: true,
      para: {smartSearchText: searchKey, pageIndex: pageIndex, pageSize: pageSize, status: searchStatus, businessType: businessType, isProfileComplete: isProfileComplete},
      pageData: true
    });
  }

  searchReviewAccounts(searchKey?: string, pageIndex: number = 0, pageSize: number = 15, asc?: boolean, status?: string): Observable<PagingData<BusinessProfile>> {
    const urlPara = '&sorts=' + 'createdDate' + (asc ? ' asc' : ' desc');
    return this._polarjService.postCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT,
      modelName: MerchantServiceModelContextPath.BUSINESS_PROFILE_ADMIN,
      requestMappingString: 'search', enableErrorMsg: true,
      urlPara: urlPara,
      para: {smartSearchText: searchKey, pageIndex: pageIndex, pageSize: pageSize, status: status},
      pageData: true
    });
  }

  approveBusinessProfile(id: number, reason?: string): Observable<BusinessProfile> {
    return this._polarjService.postCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT,
      modelName: MerchantServiceModelContextPath.BUSINESS_PROFILE_ADMIN,
      requestMappingString: `${id}/approve`, enableErrorMsg: true,
      singleData: true,
      para: reason
    });
  }

  suspendOrResumeBusinessProfile(id: number, reason: string, isSuspend: boolean): Observable<boolean> {
    return this._polarjService.postCall({
      moduleServiceName: MerchantServiceModelContextPath.MERCHANT,
      modelName: MerchantServiceModelContextPath.BUSINESS_PROFILE_ADMIN,
      requestMappingString: `${id}/suspend/${isSuspend}`, enableErrorMsg: true,
      singleData: true,
      para: reason
    });
  }

}

const MerchantServiceModelContextPath = {
  MERCHANT: 'merchant',
  ORDERS: 'amsalesorders',
  ORDERSADMIN: 'amsalesorders_admin',
  ADMIN: 'admin',
  BUSINESS_PROFILE_ADMIN: 'ambusinessprofiles_admin'
}
