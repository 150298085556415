import { inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { BusinessProfile } from '../models/sellerAccount.model';
import { MerchantService } from '../services/merchant.service';
import { PagingData } from '../models';


export interface ReviewAccountInitState {
    openAccountLists: BusinessProfile[],
    totalOpenAccountNum: number,
    isOpenAccountDescOrder: boolean,
    approvedAccountLists: BusinessProfile[],
    openAccountResponse: PagingData<BusinessProfile>,
    approveAccountResponse: PagingData<BusinessProfile>,
}

export const reviewAccountInitState: ReviewAccountInitState = {
    openAccountLists: [],
    totalOpenAccountNum: 0,
    isOpenAccountDescOrder: false,
    approvedAccountLists: [],
    openAccountResponse: {},
    approveAccountResponse: {},
};

export const ReviewAccountStore = signalStore(
    {
        providedIn: 'root',
    },
    withState(reviewAccountInitState),
    withComputed((seller) => ({
        // totalAccountNum: seller.totalOpenAccountNum
    })),
    withMethods(
        (
            seller,
            merchantSrv = inject(MerchantService),
        ) => ({
            loadOpenAccounts(
                pageIndex: number = 0,
                pageSize: number = 15,
                isDescOrder: boolean = false
            ) {
                merchantSrv
                    .searchReviewAccounts(undefined, pageIndex, pageSize, isDescOrder, 'DRAFT')
                    .subscribe((response: PagingData<BusinessProfile>) => {
                        if (response && response.dataList) {
                            patchState(seller, {
                                openAccountLists: response.dataList,
                                isOpenAccountDescOrder: isDescOrder,
                                totalOpenAccountNum: response.totalRecords,
                                openAccountResponse: response
                            });
                        }
                    });
            },
            loadApprovalAccounts(
                pageIndex: number = 0,
                pageSize: number = 15,
                isDescOrder: boolean = true
            ) {
                merchantSrv
                    .searchReviewAccounts(undefined, pageIndex, pageSize, isDescOrder, 'ACTIVE')
                    .subscribe((response: PagingData<BusinessProfile>) => {
                        if (response && response.dataList) {
                            patchState(seller, {
                                approvedAccountLists: response.dataList,
                                approveAccountResponse: response
                            });
                        }
                    });
            },
            fetchNextOpenAccount(currentReviewAccountId: number) {
                const dataList = seller.openAccountResponse().dataList || [];
                const index = dataList.findIndex(x => x.id == currentReviewAccountId);

                // in current dataList
                if ((index != -1) && index != (dataList.length - 1)) {
                    return dataList[index + 1];
                } 

                // search in next page
                const curPageIndex = seller.openAccountResponse().currentPageIndex || 0;
                const nextPageIndex = curPageIndex + 1;
                const totalPages = seller.openAccountResponse().totalPages || 0;
                
                if (nextPageIndex < totalPages) {
                    const pageSize = seller.openAccountResponse().pageSize || 0;
                    const isDescOrder = seller.isOpenAccountDescOrder();
                    this.loadOpenAccounts(nextPageIndex, pageSize, isDescOrder);
                    const newDataList = seller.openAccountLists();
                    return newDataList[0];
                }

                return {};
            }
        })
    )
);