import { Routes } from '@angular/router';
import { MainPageComponent } from './amm-pages/main-page/main-page.component';
import { forceLoginGuard } from './amm-share/guards';

export const routeConfig: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('../app/amm-pages/login-page/login-page.component').then(
        (m) => m.LoginPageComponent
      ),
    title: 'Login page',
  },
  {
    path: 'message',
    canActivate: [forceLoginGuard],
    loadComponent: () =>
      import('../app/amm-pages/message-page/message-page.component').then(
        (m) => m.MessagePageComponent
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            '../app/amm-share/components/no-conversation-selected/no-conversation-selected.component'
          ).then((m) => m.NoConversationSelectedComponent),
        outlet: 'messageConversations',
      },
      {
        path: ':topmsgId',
        loadComponent: () =>
          import(
            '../app/amm-pages/message-content/message-content.component'
          ).then((m) => m.MessageContentComponent),
        outlet: 'messageConversations',
      },
    ],
    title: 'Message page',
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'main',
    canActivate: [forceLoginGuard],
    component: MainPageComponent,
    children: [
      {
        path: 'home',
        loadComponent: () =>
          import('../app/amm-pages/home/home.component').then(
            (m) => m.HomeComponent
          ),
        outlet: 'mainBody',
        title: 'Home page'
      },
      {
        path: 'seller-accounts',
        loadComponent: () =>
          import('../app/amm-pages/seller-accounts-page/seller-accounts-page.component').then(
            (m) => m.SellerAccountsPageComponent
          ),
        outlet: 'mainBody',
        title: 'Seller accounts page'
      },
      {
        path: 'seller-details/:sellerId',
        loadComponent: () =>
          import('../app/amm-pages/seller-detail-page/seller-detail-page.component').then(
            (m) => m.SellerDetailPageComponent
          ),
        outlet: 'mainBody',
        title: 'Seller detail page'
      },
      {
        path: 'review-accounts',
        loadComponent: () =>
          import('../app/amm-pages/review-accounts-page/review-accounts-page.component').then(
            (m) => m.ReviewAccountsPageComponent
          ),
        outlet: 'mainBody',
        title: 'Review accounts page'
      },
      {
        path: 'review-account-detail/:accountId/:status',
        loadComponent: () =>
          import('../app/amm-pages/review-account-detail-page/review-account-detail-page.component').then(
            (m) => m.ReviewAccountDetailPageComponent
          ),
        outlet: 'mainBody',
        title: 'Review account detail page'
      },
      {
        path: 'buyers',
        loadComponent: () =>
          import('../app/amm-pages/buyers-page/buyers-page.component').then(
            (m) => m.BuyersPageComponent
          ),
        outlet: 'mainBody',
        title: 'Buyers page'
      },
      {
        path: 'order-details/:poNumber',
        loadComponent: () =>
          import('../app/amm-pages/order-detail-page/order-detail-page.component').then(
            (m) => m.OrderDetailPageComponent
          ),
        outlet: 'mainBody',
        title: 'Orders page'
      },
      {
        path: 'payout-details/:payoutCode',
        loadComponent: () =>
          import('../app/amm-pages/payout-detail-page/payout-detail-page.component').then(
            (m) => m.PayoutDetailPageComponent
          ),
        outlet: 'mainBody',
        title: 'Payout detail page'
      },
      {
        path: 'transaction/:transactionCode',
        loadComponent: () =>
          import('../app/amm-pages/transaction-detail-page/transaction-detail-page.component').then(
            (m) => m.TransctionDetailPageComponent
          ),
        outlet: 'mainBody',
        title: 'Transaction detail page'
      },
      {
        path: 'orders',
        loadComponent: () =>
          import('../app/amm-pages/orders-page/orders-page.component').then(
            (m) => m.OrdersPageComponent
          ),
        outlet: 'mainBody',
        title: 'Orders page'
      },
      {
        path: 'financial/:section',
        loadComponent: () =>
          import('../app/amm-pages/overview-financial-page/overview-financial-page.component').then(
            (m) => m.OverviewFinancialPageComponent
          ),
        outlet: 'mainBody',
        title: 'Overview financial page'
      },
      {
        path: 'seller-payouts',
        loadComponent: () =>
          import('../app/amm-pages/seller-payouts-page/seller-payouts-page.component').then(
            (m) => m.SellerPayoutsPageComponent
          ),
        outlet: 'mainBody',
        title: 'Seller payouts page'
      },
      {
        path: 'statistics',
        loadComponent: () =>
          import('../app/amm-pages/statistic-page/statistic-page.component').then(
            (m) => m.StatisticPageComponent
          ),
        outlet: 'mainBody',
        title: 'Statistics page'
      },
    ],
    title: 'Ammall Admin'
  },
];

export default routeConfig;
