<div class="flex">
    <amm-navigation-side-bar></amm-navigation-side-bar>
    <div class="flex flex-col flex-1" style="max-width: calc(100% - 280px);">
        <router-outlet name="mainBody"></router-outlet>
    </div>
    <amm-toast-with-action
        toastKey="page-operation-toast"
        (onToastClose)="onToastClose($event)"
        (onBtnAction)="onBtnAction($event)">
    </amm-toast-with-action>
</div>
